import './App.scss';
import Blogs from './components/blogs';
import Hobbies from './components/hobbies';
import PerInfo from './components/perInfo';
import ProInfo from './components/proInfo';
import Youtube from './components/youtube';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <title>Arth Panchani</title>
        <meta name="title" content="Arth Panchani"></meta>
        <meta name="description" content="Hi I am Arth Panchani ! this website showcases my overall profile personal & professional skills and many things"></meta>
      </header>
      <body>
        <PerInfo></PerInfo>
        <ProInfo></ProInfo>
        <Hobbies></Hobbies>
        <Youtube></Youtube>
        <Blogs></Blogs>
        <h3 className='footer-text'>Designed and Developed by Arth with ReactJS</h3>
      </body>
    </div>
  );
}

export default App;

import React, { Component } from 'react';
import '../styles/perInfo.scss';

class PerInfo extends Component { 
    constructor(props) {
        super(props)
        this.state = { doorsClosed: true }
    }
    render() {
        const doorsClosed = this.state.doorsClosed;
        const doorsClasses = [
            "closed-plate",
            doorsClosed ? '' : 'closed-plate-fade-cls'
          ];
        const leftdoorClasses = [
            "left-door",
            doorsClosed ? '' : 'left-door-open'
        ]
        const rightdoorClasses = [
            "right-door",
            doorsClosed ? '' : 'right-door-open'
        ]
        const photoClasses = [
            "my-photo",
            doorsClosed ? '' : 'my-photo-visible'
        ]
        return (
            <div className='perinfo-wrapper'>
                <div className='photo-area'>
                    <div className='door-frame'>
                        <div className={leftdoorClasses.join(' ')}></div>
                        <div className={rightdoorClasses.join(' ')}></div>
                        <div onClick={() => this.setState({ doorsClosed : false })} className={doorsClasses.join(' ')}>
                            <h3 className='closed-title'><i className="fa-solid fa-shield-halved"></i></h3>
                            <p className='closed-desc'>Tap to Enter</p>
                        </div>
                        <img src='arth2.png' className={photoClasses.join(' ')} alt='ArthPhoto'></img>
                        <img src='clock.png' className='clock-img' alt="WallClock"></img>
                    </div>
                </div>
                <div className='details-area'>
                    <h1 className='my-name'>Arth Panchani</h1>
                    <h2 className='my-titles'>Software Engineer, Writer</h2>
                    <div className='icon-bar'>
                        <a href='https://www.instagram.com/arth_40' target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                        <a href='https://www.youtube.com/channel/UCH2pT_r1K6Z7Kz81n2C7NNg' target='_blank' rel="noreferrer"><i className="fa-brands fa-youtube"></i></a>
                        <a href='https://www.facebook.com/Arth.Panchani' target='_blank' rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                        <a href='https://www.snapchat.com/add/arth_40?share_id=eHRXAC6XBXY&locale=en-IN' target='_blank' rel="noreferrer"><i className="fa-brands fa-snapchat"></i></a>
                        <a href='https://www.linkedin.com/in/arth-panchani-1a2079127' target='_blank' rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                        <a href='https://github.com/arth40' target='_blank' rel="noreferrer"><i className="fa-brands fa-github"></i></a>
                        <a href='https://twitter.com/Arth_40' target='_blank' rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                    </div>
                </div>
                <div className='about-me'>
                    <p className='about-me-desc'>Hey! You really found my page <i className="fa-regular fa-paper-plane"></i> anyways you are welcomed here. Talking about my self, by Degree <i className="fa-solid fa-graduation-cap"></i> I am a Computer Engineer, by profession I am a Software Engineer <i className="fa-solid fa-code"></i> and by hobby I am a Writer <i className="fa-regular fa-pen-to-square"></i>.</p>
                </div>
            </div>
        );
    }
}
 
export default PerInfo;
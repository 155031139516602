import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/youtube.scss';

const YoutubeFunction = () => {
    const [videos, setVideos] = useState([]);
    useEffect(() => {
        const apiLink = "https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=UCH2pT_r1K6Z7Kz81n2C7NNg&maxResults=3&key=AIzaSyAMjJXQCevoYCIQjZe1fCDcMTg5C1jI4-g";
        axios.get(`${apiLink}`, { headers: { Accept: "application/json" } }).then(res =>{
            if(res.status === 200){
                // console.log('Youtube Videos: ',res.data.items);
                let temp = res.data.items;
                temp = temp.map((data) => {
                    // console.log('Videoid: ',data.id.videoId);
                    data.link = 'https://www.youtube.com/embed/' + data.id.videoId;
                    return data;
                })
                setVideos(temp);
            }
        }); 
    }, []);

    return (
    <div className='youtube-area'>
        {videos && videos.map((vid, index) => (
                <div className='youtube-video' id='my-blogpost'>
                    <iframe className="ytplayer" title={vid.snippet.title} type="text/html" src={vid.link} frameborder="0"></iframe>
                </div>
        ))}
    </div>
    );
}

class Youtube extends Component {
    state = {  }
     
    render() {
        return (
            <div className='yt-wrapper'>
                <h2 className='yt-title'>My Latest Youtube Videos &ensp;<a href='https://www.youtube.com/channel/UCH2pT_r1K6Z7Kz81n2C7NNg' target='_blank' rel="noreferrer"><span className='yt-link'>All Videos click here <i class="fa-solid fa-link"></i></span></a></h2>
                <YoutubeFunction></YoutubeFunction>
            </div>
        );
    }
}
 
export default Youtube;
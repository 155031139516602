import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/blogs.scss';

const BlogsFunction = () => {
    const [blog, setBlogs] = useState([]);
    useEffect(() => {
        const apiLink = "https://www.googleapis.com/blogger/v3/blogs/8266781855822708229/posts?key=AIzaSyBRdI6Ei9dQ7tLBmZ5tjlcS5HoLgcmhzeo";
        axios.get(`${apiLink}`, { headers: { Accept: "application/json" } }).then(res =>{
            if(res.status === 200){
                console.log(res.data.items);
                // setBlogs(res.data.items);
                let temp = res.data.items?.filter((value,i) => {return i < 3} )
                console.log( temp)
                setBlogs(temp);
            }
        }); 
    }, []);

    return (
    <div className='blog-area'>
        {blog && blog.map((b, index) => (
                <div className='blogpost' id='my-blogpost' data-tooltip-content='Click to Read full blog'>    
                    <a href={b.url} target='_blank' rel="noreferrer">
                    <h4 className='click-blog'>Click to Read Full Blog</h4>
                    <h3 className='blogpost-title'>
                        {b.title}
                    </h3>
                    <div className="blogpost-content" key={index} dangerouslySetInnerHTML={ {__html: b.content}}>
                    </div>
                    </a>
                </div>
        ))}
    </div>
    );
}

class Blogs extends Component {
    state = {  }
     
    render() {
        return (
            <div className='blogs-wrapper'>
                <h2 className='blogs-title'>My Latest Blogs &ensp;<a href='https://livethelifebyarth.blogspot.com/' target='_blank' rel="noreferrer"><span className='blog-link'>All Blogs click here <i class="fa-solid fa-link"></i></span></a></h2>
                <BlogsFunction></BlogsFunction>
            </div>
        );
    }
}
 
export default Blogs;
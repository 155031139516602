import React, { Component } from 'react';
import '../styles/hobbies.scss';

class Hobbies extends Component {
    state = {  } 
    render() { 
        return (
            <div className='hobbies-wrapper'>
                <h2 className='hobbies-title'>My kind of things</h2>
                <div className='icons-flex'>
                    <i className="fa-solid fa-car"></i>
                    <i className="fa-solid fa-location-dot"></i>
                    <i className="fa-solid fa-wifi"></i>
                    {/* <i className="fa-solid fa-plane"></i> */}
                    <i className="fa-solid fa-person-walking-luggage"></i>
                    <i className="fa-solid fa-pen-clip"></i>
                    <i className="fa-solid fa-mobile-screen"></i>
                </div>
            </div>
        );
    }
}
 
export default Hobbies;
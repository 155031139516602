import React, { Component } from 'react';
import '../styles/proInfo.scss';

class ProInfo extends Component {
    state = {  } 
    render() { 
        return (
            <div className='proinfo-wrapper'>
                <div className='proinfo-part-2'>
                    <h2>Higher Secondary</h2>
                    <img src='logos/gurukul-logo.png' alt='GurukulLogo'></img>
                    <div className='vertical-flex'>
                        <h3>B.A.P.S. Swaminarayan Vidyamandir Gondal</h3>
                        <h4>11-12th Science [ Maths-Physics-Chemistry ] <span className='study-years'>(2015-2017)</span></h4>
                    </div>
                </div>
                <div className='proinfo-part-2'>
                    <h2>University</h2>
                    <img src='logos/pdeu-logo.png' alt='PDEULogo'></img>
                    <div className='vertical-flex'>
                        <h3>Pandit Deendayal Energy University</h3>
                        <h4>B.Tech [ Computer Engineering ] <span className='study-years'>(2017-2021)</span></h4>
                    </div>
                </div>
                <h3 className='current-work'><i className="fa-solid fa-laptop-code"></i> Software Engineer at IESoft Technologies ( July 2021 - Current )</h3>
                <div className='skill-set'>
                    <i className="fa-brands fa-angular"></i>
                    <i className="fa-brands fa-react"></i>
                    <img src="icons/ionic-colored.svg" alt="IonicLogo"></img>
                    <i className="fa-brands fa-node"></i>
                    <img src="icons/graphqlname.png" alt="IonicLogo"></img>
                    <i className="fa-brands fa-aws"></i>
                    <i className="fa-solid fa-c"></i>
                </div>
                <h4 className='skillset-title'>SKILLSET <i className="fa-solid fa-arrow-trend-up"></i> AND MORE <i className="fa-solid fa-plus"></i></h4>
            </div>
        );
    }
}
 
export default ProInfo;